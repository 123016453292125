import { User } from "../../models/User";
import { Status } from "../../models/Status";
import { createAction } from "../../models/Action";
import history from "../../utils/history";
import { showMessageBox } from "../MessageBox/actions";
import * as T from "./types";
import {
  Auth,
  getCurrentUser,
  isAuthenticated,
  signOutCognito,
} from "../../utils/auth";

export const login = (user: User, gotopath = "") => {
  return async (dispatch) => {
    dispatch(createAction(T.LOGIN_LOGIN, user));
    dispatch(createAction(T.LOGIN_STATUS, Status.createLoading()));

    try {
      if (!user.username) throw new Error("no username provided");
      const session = await (await Auth()).signIn(user.username, user.password);
      dispatch(createAction(T.LOGIN_AUTH, true));
      dispatch(createAction(T.LOGIN_SESSION, session));

      if (gotopath.length > 0 && gotopath.indexOf(".") === -1) {
        history.push("/" + gotopath);
      } else {
        history.goBack();
      }
    } catch (e: unknown) {
      let msg = "An unknown error has occurred";
      if ((e instanceof Error || typeof e === "object") && e) {
        msg = (e as Record<string, unknown>).message as string;
      }
      dispatch(createAction(T.LOGIN_STATUS, Status.createFailed(msg)));
      dispatch(showMessageBox(msg));
    }
  };
};

export const resetStatus = () => {
  return (dispatch) => {
    dispatch(createAction(T.LOGIN_STATUS, new Status()));
  };
};

export const checkLogin = () => {
  return async (dispatch) => {
    const session = await getCurrentUser();
    dispatch(createAction(T.LOGIN_AUTH, await isAuthenticated()));
    dispatch(createAction(T.LOGIN_SESSION, session));
  };
};

export const signOut = () => {
  return async (dispatch) => {
    const hasSignedOut = await signOutCognito();
    if (hasSignedOut) {
      dispatch(createAction(T.LOGIN_AUTH, false));
      dispatch(createAction(T.LOGIN_USER, {}));
      history.replace("/login" + window.location.search);
    }
  };
};

export const noPermission = () => {
  return (dispatch) => {
    dispatch(
      showMessageBox("This page is protected. Please login first.", "error")
    );
  };
};
