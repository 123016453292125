import { Status } from "../../models/Status";
import * as T from "./types";

const initialState: T.IState = {
  status: new Status(),
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.SET_PASSWORD_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
