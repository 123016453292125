import local from "../config/config.local.json";
import development from "../config/config.dev.json";
import production from "../config/config.prod.json";
export const loadConfig = () => {
  const env = process.env.NODE_ENV || "local";

  return {
    test: local,
    local,
    development,
    production,
  }[env];
};

export default loadConfig;
