import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";
import login from "../components/Login/reducers";
import signUp from "../components/SignUp/reducers";
import messageBox from "../components/MessageBox/reducers";
import confirmAccount from "../components/ConfirmAccount/reducers";
import resetPassword from "../components/ResetPassword/reducers";
import setPassword from "../components/SetPassword/reducers";
import changePassword from "../components/ChangePassword/reducers";
import privacy from "../components/PrivacyTakedown/reducers";
import download from "../components/Download/reducers";
import leaderboard from "../components/LeaderBoard/reducers";
import markdown from "../partials/Markdown/reducers";

export default combineReducers({
  routing: routerReducer,
  form: formReducer,
  login,
  signUp,
  messageBox,
  confirmAccount,
  resetPassword,
  setPassword,
  changePassword,
  privacy,
  download,
  leaderboard,
  markdown,
});
