import * as T from "./types";

const initialState: T.IState = {
  type: "error",
  message: "",
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.MESSAGE_BOX:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
