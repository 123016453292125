import { Redirect, Route, Switch } from "react-router-dom";
import { lazy, Suspense } from "react";
import MessageBox from "../components/MessageBox/MessageBox";
import { Helmet } from "react-helmet"; // Manages changes to document head

import {
  NotFound404,
  TermsOfUseNonCommercial,
  PrivacyPolicy,
  PrivacyTakedown,
  Login,
  SignUp,
  SignOut,
  ResetPassword,
  SetPassword,
  ChangePassword,
  Download,
  canonicalURL,
  PageRoute,
} from "../components/Routes";

const LandingNuReality = lazy(
  () => import(/* webpackChunkName: "Landing" */ "./Landing/Landing")
);

const About = lazy(
  () => import(/* webpackChunkName: "About" */ "./About/about")
);
const FAQ = lazy(() => import(/* webpackChunkName: "FAQ" */ "./FAQ/faq"));

// placeholder
const nuPlanPaths = ["/nuplan"];
const nuRealityPaths = ["/nureality"];
export const isNuPlanPath = nuPlanPaths.includes(window.location.pathname);
export const isNuRealityPath = nuRealityPaths.includes(
  window.location.pathname
);

console.log("canonicalURL (is it valid FIRST try??): ", canonicalURL);

export const RoutesNuReality = (): JSX.Element => (
  <div className="full-height">
    {canonicalURL ? (
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
      </Helmet>
    ) : null}
    <Suspense fallback={<div>loading</div>}>
      <Switch>
        <PageRoute path="/" exact component={LandingNuReality} />

        <PageRoute path="/about" exact component={About} />
        <PageRoute path="/faq" exact component={FAQ} />

        <PageRoute path="/download" exact component={Download} />
        <PageRoute path="/login" exact component={Login} />
        <PageRoute path="/sign-up" exact component={SignUp} />
        <PageRoute path="/sign-out" exact component={SignOut} />
        <PageRoute path="/reset-password" exact component={ResetPassword} />
        <PageRoute path="/set-password" exact component={SetPassword} />
        <PageRoute
          path="/change-password"
          exact
          component={ChangePassword}
          isProtected
        />

        <PageRoute path="/privacy" exact component={PrivacyPolicy} />
        <PageRoute path="/privacy-takedown" exact component={PrivacyTakedown} />
        <PageRoute
          path="/terms-of-use"
          exact
          component={TermsOfUseNonCommercial}
        />
        <Route path="/nureality" exact>
          <Redirect to="/" />
        </Route>

        <PageRoute path="*" component={NotFound404} />
      </Switch>
    </Suspense>
    <MessageBox />
  </div>
);

export default RoutesNuReality;
