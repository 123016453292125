import { IMenu } from "./MenusNuScenes";

const menusNuReality: IMenu[] = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "nuScenes",
    href: "",
    subMenus: [
      {
        label: "nuScenes",
        href: "https://www.nuscenes.org/nuscenes",
      },
      {
        label: "nuImages",
        href: "https://www.nuscenes.org/nuimages",
      },
      {
        label: "nuPlan",
        href: "https://www.nuscenes.org/nuplan",
      },
    ],
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "FAQ",
    href: "/faq",
  },
  {
    label: "Legal",
    href: "",
    subMenus: [
      {
        label: "Terms of use",
        href: "/terms-of-use",
      },
      {
        label: "Privacy Policy",
        href: "/privacy",
      },
    ],
  },

  {
    label: "Download",
    href: "/download",
  },
];

export { menusNuReality };
