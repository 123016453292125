/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import ReactGA from "react-ga";
import { KeyboardArrowDown, Launch as OpenInNewIcon } from "@material-ui/icons";
import { NavLink, NavLinkProps } from "react-router-dom";
import history from "../../utils/history";
import Responsive from "../../partials/Responsive";
import HeaderMobile from "./HeaderMobile";
import { IMenu, menusNuScenes } from "./MenusNuScenes";
import { menusNuReality } from "./MenusNuReality";
import styles from "./header.module.scss";
import logo from "../../assets/images/nuScenes_logo_w.svg";
import nuRealityLogo from "../../assets/images/nuReality/nuReality_logo_w.svg";
import nuPlanLogo from "../../assets/images/nuPLAN_logo_w.svg";
import { weAreOnNuPlanDomain, weAreOnNuRealityDomain } from "../../shared";

export const nuLogoImgSrc = weAreOnNuPlanDomain
  ? nuPlanLogo
  : weAreOnNuRealityDomain
  ? nuRealityLogo
  : logo;

export const CrossLink = ({
  external,
  to,
  activeClassName,
  children,
  ...rest
}: NavLinkProps & { external?: string }): JSX.Element => {
  if (typeof to !== "string") {
    // because I'm lazy
    throw new Error("CrossLink does not handle non string `to` param");
  }

  if (to === "")
    return (
      <span {...rest} style={{ cursor: "default" }}>
        {children}
      </span>
    );

  if (to.startsWith("http")) {
    return (
      <a href={to} {...rest} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  } else if (external) {
    return (
      <a href={external + to} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <NavLink to={to} activeClassName={activeClassName} {...rest}>
      {children}
    </NavLink>
  );
};

class Header extends React.Component<
  any,
  {
    path: string;
    hoverMenu: IMenu | null;
    highlightMenusBg: boolean;
  }
> {
  removeListener: () => void;

  constructor(props) {
    super(props);
    this.state = {
      path: window.location.pathname,
      hoverMenu: null,
      highlightMenusBg: false,
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.removeListener = () => {};
  }

  componentDidMount() {
    this.highlightMenusBg();
    window.addEventListener("scroll", this.highlightMenusBg);
    this.removeListener = history.listen((location) => {
      this.setState({
        path: location.pathname,
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }

  componentWillUnmount() {
    this.removeListener();
    window.removeEventListener("scroll", this.highlightMenusBg);
  }

  highlightMenusBg = () => {
    const scrollTop = window.pageYOffset;
    this.setState({
      highlightMenusBg: scrollTop > 200,
    });
  };

  render() {
    const { hoverMenu, highlightMenusBg } = this.state;
    const menus = weAreOnNuRealityDomain ? menusNuReality : menusNuScenes;

    const bgClass = highlightMenusBg ? styles.highlight : "";

    return (
      <div style={{ position: "fixed", width: "100%", zIndex: 10 }}>
        <Responsive name="desktop">
          <header className={styles.header + " " + bgClass}>
            <div className={`content flex ${styles.content} ${bgClass}`}>
              <div>
                <CrossLink exact to="/" className="vertical-center">
                  <img
                    src={nuLogoImgSrc}
                    alt="logo"
                    className={styles.logoImg}
                  />
                </CrossLink>
              </div>

              <ul className={"flex vertical-center " + styles.menus}>
                {menus.map((menu) => {
                  return (
                    <li
                      key={menu.label}
                      className={styles.menu}
                      onMouseEnter={this.setHoverMenu(menu)}
                      onMouseLeave={this.setHoverMenu()}
                    >
                      {menu.label === "Download" ? (
                        <CrossLink
                          external={
                            /*weAreOnNuPlanDomain
                              ? "https://www.nuscenes.org"
                              :*/ undefined
                          }
                          to={menu.href}
                          className={styles.signUp}
                        >
                          {menu.label}
                        </CrossLink>
                      ) : (
                        <CrossLink
                          exact
                          external={
                            /*!menu.href.startsWith("http") &&
                            menu.href !== "/nuplan" &&
                            weAreOnNuPlanDomain
                              ? "https://www.nuscenes.org"
                              :*/ undefined
                          }
                          to={menu.href}
                          activeClassName={styles.active}
                          className={styles.menuLink + " " + bgClass}
                        >
                          {menu.label}
                          {menu.href.startsWith("http") && (
                            <OpenInNewIcon
                              style={{
                                height: "14px",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          {menu.subMenus && (
                            <KeyboardArrowDown
                              style={{ fontSize: 20 }}
                              className={
                                styles.downIcon +
                                " " +
                                (hoverMenu === menu ? styles.active : "")
                              }
                            />
                          )}
                        </CrossLink>
                      )}
                      {menu.subMenus && hoverMenu === menu && (
                        <ul
                          className={styles.subMenus}
                          style={{
                            left: menu.submenuHorizOffset
                              ? menu.submenuHorizOffset
                              : "5px",
                          }}
                        >
                          {menu.subMenus.map((s) => {
                            return (
                              <li key={s.label} className={styles.subMenu}>
                                {s.href.indexOf("http") === 0 ? (
                                  <a
                                    href={s.href}
                                    target="_blank"
                                    className={styles.subMenu}
                                    rel="noreferrer"
                                  >
                                    {s.label}
                                    <OpenInNewIcon
                                      style={{
                                        height: "14px",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <CrossLink
                                    exact
                                    external={
                                      /*weAreOnNuPlanDomain
                                        ? "https://www.nuscenes.org"
                                        :*/ undefined
                                    }
                                    to={s.href}
                                    activeClassName={styles.active}
                                    className={styles.subMenu}
                                  >
                                    {s.label}&nbsp;
                                  </CrossLink>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </header>
        </Responsive>
        <Responsive name="phone">
          <HeaderMobile />
        </Responsive>
      </div>
    );
  }

  private setHoverMenu =
    (menu: IMenu | null = null) =>
    () => {
      this.setState({
        hoverMenu: menu,
      });
    };
}

export default Header;
