import BaseLayout from "../Layouts/BaseLayout";
import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import history from "../../utils/history";
import { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";

// const styles = require("../../partials/Header/header.less");

export default ({ Component, isProtected, spacerSize = 60, rest }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [prevPath, setPrevPath] = useState("");
  const [didScroll, setDidScroll] = useState(false);

  // Without this, when the user navigates between pages, the vertical scroll bar won't reset to the top of the page!
  useEffect(() => {
    return history.listen((location, action) => {
      if (location.hash === "") {
        divRef?.current?.scrollTo(0, 0);
      }
      // We want didScroll to apply only to the current page. So reset it when changing pages.
      if (prevPath !== location.pathname) {
        console.log("Resetting didScroll.", location.pathname, prevPath);
        setPrevPath(location.pathname);
        setDidScroll(false);
      }
    });
  }, [divRef, prevPath]);

  // For some reason, loading a URL with a hash in it was not bringing us to that section of the page. This hack fixes that.
  const handleLoaded = () => {
    const elementToScrollTo = document.getElementById(
      window.location.hash.replace("#", "")
    );
    if (!didScroll && elementToScrollTo && divRef?.current) {
      setDidScroll(true);
      setTimeout(() => {
        elementToScrollTo?.scrollIntoView(true);
      }, 10);
    }
  };

  return (
    <BaseLayout isProtected={isProtected}>
      <Header />
      <div style={{ height: `${spacerSize}px` }} />
      <div
        style={{
          overflow: "scroll",
          height: `calc(100vh - ${spacerSize}px)`,
        }}
        ref={divRef}
        onLoad={handleLoaded}
      >
        <Component {...rest} />
        <Footer />
      </div>
    </BaseLayout>
  );
};
