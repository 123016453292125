import { Status } from "../../models/Status";

export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGIN_LOGIN = "LOGIN_LOGIN";
export const LOGIN_AUTH = "LOGIN_AUTH";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SESSION = "LOGIN_SESSION";

export type IState = {
  status: Status;
  user: any;
  cognitoSession: any;
  auth: boolean; // login successfully if `auth` is `true`.
};
