import { Status } from "../../models/Status";

export const DOWNLOAD_STATUS = "DOWNLOAD_STATUS";
export const DOWNLOAD_FILES_QUEUE = "DOWNLOAD_FILES_QUEUE";
export const DOWNLOAD_FILES_UNQUEUE = "DOWNLOAD_FILES_UNQUEUE";
export const DOWNLOAD_GET_REGIONS = "DOWNLOAD_GET_REGIONS";
export const DOWNLOAD_ING_REGION = "DOWNLOAD_ING_REGION";

export type IState = {
  status: Status;
  filesDownloading: string[];
  regions: Record<string, unknown>[];
  downloadingRegion: string;
};
