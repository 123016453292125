import { Status } from "../../models/Status";
import * as T from "./types";
import _ from "lodash";

const initialState: T.IState = {
  status: new Status(),
  filesDownloading: [],
  regions: [],
  downloadingRegion: "",
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.DOWNLOAD_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case T.DOWNLOAD_FILES_QUEUE:
      return {
        ...state,
        filesDownloading: _.uniq([...state.filesDownloading, action.payload]),
      };

    case T.DOWNLOAD_FILES_UNQUEUE:
      return {
        ...state,
        filesDownloading: [..._.pull(state.filesDownloading, action.payload)],
      };

    case T.DOWNLOAD_GET_REGIONS:
      return {
        ...state,
        regions: [...action.payload],
      };

    case T.DOWNLOAD_ING_REGION:
      return {
        ...state,
        downloadingRegion: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
