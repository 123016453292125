import { captureException } from "@sentry/react";
import { loadConfig } from "./config";
type Amplify = typeof import("aws-amplify").default;

const appConfig = loadConfig();

let authLoaded = false;
let Amp: Amplify;
export const Auth = async (): Promise<Amplify["Auth"]> => {
  if (!authLoaded) {
    try {
      Amp = (await import("aws-amplify")).default;
      Amp.configure({
        Auth: appConfig.cognito,
        Storage: appConfig.storage,
      });
      authLoaded = true;
    } catch (e) {
      captureException(e);
    }
  }
  return Amp.Auth;
};

export const isAuthenticated = async (): Promise<boolean> => {
  try {
    const user = await (await Auth()).currentAuthenticatedUser();
    return !!user;
  } catch (e) {
    return false;
  }
};

export const getCurrentUser = async () => {
  try {
    const user = await (await Auth()).currentAuthenticatedUser();
    return user;
  } catch (e) {
    return null;
  }
};

export const signOutCognito = async (): Promise<boolean> => {
  try {
    await (await Auth()).signOut();
    return true;
  } catch (e) {
    return false;
  }
};
