import { Status } from "../../models/Status";
import * as T from "./types";

const initialState: T.IState = {
  status: new Status(),
  user: JSON.parse(localStorage.getItem("user") || "{}"),
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.SIGN_UP_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case T.SIGN_UP_USER:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
