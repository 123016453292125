import { Status } from "../../models/Status";
import * as T from "./types";

const initialState: T.IState = {
  user: {},
  status: new Status(),
  auth: false,
  cognitoSession: {},
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.LOGIN_LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case T.LOGIN_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case T.LOGIN_AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    case T.LOGIN_USER:
      return {
        ...state,
        user: { ...action.payload },
      };
    case T.LOGIN_SESSION:
      return {
        ...state,
        cognitoSession: { ...action.payload },
      };

    default:
      return {
        ...state,
      };
  }
};
