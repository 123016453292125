import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { FC, lazy, Suspense } from "react";
import Layout from "./Layouts/Layout";
import MessageBox from "./MessageBox/MessageBox";
import { Helmet } from "react-helmet"; // Manages changes to document head
import { weAreOnNuPlanDomain } from "../shared";

// ---------------- shared (nuSenes and nuReality) ------------------
export const NotFound404 = lazy(
  () => import(/* webpackChunkName: "NotFound404" */ "./NotFound404")
);
export const TermsOfUseNonCommercial = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUseNonCommercial" */ "./TermsOfUse/TermsOfUseNonCommercial"
    )
);
export const PrivacyPolicy = lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPolicy" */ "../components/PrivacyPolicy/PrivacyPolicy"
    )
);
export const Login = lazy(
  () => import(/* webpackChunkName: "Login" */ "./Login")
);
export const SignUp = lazy(
  () => import(/* webpackChunkName: "SignUp" */ "./SignUp")
);
export const SignOut = lazy(
  () => import(/* webpackChunkName: "SignOut" */ "./SignOut")
);
export const ResetPassword = lazy(
  () => import(/* webpackChunkName: "ResetPassword" */ "./ResetPassword")
);
export const SetPassword = lazy(
  () => import(/* webpackChunkName: "SetPassword" */ "./SetPassword")
);
export const ChangePassword = lazy(
  () => import(/* webpackChunkName: "ChangePassword" */ "./ChangePassword")
);

export const PrivacyTakedown = lazy(
  () => import(/* webpackChunkName: "PrivacyTakedown" */ "./PrivacyTakedown")
);
export const Download = lazy(
  () => import(/* webpackChunkName: "Download" */ "./Download")
);

// ----------------- nuScenes --------------------

const Landing = lazy(
  () => import(/* webpackChunkName: "Landing" */ "./Landing")
);

const ComingSoon = lazy(
  () => import(/* webpackChunkName: "ComingSoon" */ "./ComingSoon/comingSoon")
);

const ConfirmAccount = lazy(
  () => import(/* webpackChunkName: "ConfirmAccount" */ "./ConfirmAccount")
);

const TermsOfUseCommercial = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUseCommercial" */ "../components/TermsOfUse/TermsOfUseCommercial"
    )
);

const CommercialOfferings = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUseCommercial" */ "../components/TermsOfUse/commercial-offerings"
    )
);

const Team = lazy(() => import(/* webpackChunkName: "Team" */ "./Team/Team"));
const ObjectDetection = lazy(
  () => import(/* webpackChunkName: "ObjectDetection" */ "./ObjectDetection")
);
const LidarSegmentation = lazy(
  () =>
    import(/* webpackChunkName: "LidarSegmentation" */ "./LidarSegmentation")
);
const Gallery = lazy(
  () => import(/* webpackChunkName: "Gallery" */ "./Gallery")
);
const Tracking = lazy(
  () => import(/* webpackChunkName: "Tracking" */ "./Tracking")
);
const Publications = lazy(
  () => import(/* webpackChunkName: "Publications" */ "./Publications")
);
const NuImages = lazy(
  () => import(/* webpackChunkName: "NuImages" */ "./NuImages")
);
const NuScenes = lazy(
  () => import(/* webpackChunkName: "NuScenes" */ "./NuScenes")
);
const NuPlan = lazy(
  () => import(/* webpackChunkName: "NuPlan" */ "./nuPlan/nuplan")
);
const Prediction = lazy(
  () => import(/* webpackChunkName: "prediction" */ "./Prediction")
);
const Panoptic = lazy(
  () => import(/* webpackChunkName: "panoptic" */ "./panoptic/panoptic")
);
// Heartbeat has a list of all public routes. Any updates to public routes should also update the Heartbeat.
export const PageRoute = ({
  component: Component,
  isProtected = false,
  headerSize = 50,
  ...rest
}): JSX.Element => (
  <Route
    {...rest}
    render={(props): JSX.Element => (
      <Layout
        Component={Component}
        rest={props}
        isProtected={isProtected}
        spacerSize={headerSize}
      />
    )}
  />
);
interface ExploreRedirectParams {
  sceneId: string;
  frame: string;
}
const ExploreRedirect: FC = () => {
  const params = useParams<ExploreRedirectParams>();
  return (
    <Redirect
      to={`/nuscenes?sceneId=${params.sceneId}&frame=${params.frame}`}
    />
  );
};

// placeholder
const nuPlanPaths = ["/nuplan"];
const nuRealityPaths = ["/nureality"];
export const isNuPlanPath = nuPlanPaths.includes(window.location.pathname);
export const isNuRealityPath = nuRealityPaths.includes(
  window.location.pathname
);

export const canonicalURL = getCanonicalURL();

// SEO. See: https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls
function getCanonicalURL() {
  let canonicalURL = "";
  if (window.location.hostname !== "www.nuplan.org" && isNuPlanPath) {
    canonicalURL =
      "https://www.nuplan.org" +
      window.location.pathname +
      window.location.search;
  }

  if (window.location.hostname !== "www.nureality.org" && isNuRealityPath) {
    canonicalURL =
      "https://www.nureality.org" +
      window.location.pathname +
      window.location.search;
  }
  if (
    window.location.hostname !== "www.nuscenes.org" &&
    !isNuRealityPath &&
    !isNuPlanPath
  ) {
    canonicalURL =
      "https://www.nuscenes.org" +
      window.location.pathname +
      window.location.search;
  }
  return canonicalURL;
}

export const RoutesNuScenes = (): JSX.Element => (
  <div className="full-height">
    {canonicalURL ? (
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
      </Helmet>
    ) : null}
    <Suspense fallback={<div>loading</div>}>
      {/* Instant redirect from nuplan.org to nuscenes.org/nuplan before user sees anything or clicks anything.
          Note that <Redirect> does not support redirecting to an external site, so we use this method from Stack Overflow. */}
      {weAreOnNuPlanDomain && (
        <Route
          path="*"
          component={() => {
            window.location.href = "https://www.nuscenes.org/nuplan";
            return null;
          }}
        />
      )}
      <Switch>
        <PageRoute path="/" exact component={Landing} />
        <PageRoute path="/login" exact component={Login} />
        <PageRoute path="/team" exact component={Team} />
        <PageRoute path="/about" exact component={Team} />
        <PageRoute path="/sign-up" exact component={SignUp} />
        <PageRoute path="/sign-out" exact component={SignOut} />
        <Route path="/explore/:sceneId/:frame?" exact>
          <ExploreRedirect />
        </Route>
        <PageRoute path="/download" exact component={Download} />
        <PageRoute path="/confirm-account" exact component={ConfirmAccount} />
        <PageRoute path="/reset-password" exact component={ResetPassword} />
        <PageRoute path="/set-password" exact component={SetPassword} />
        <PageRoute
          path="/terms-of-use"
          exact
          component={TermsOfUseNonCommercial}
        />
        <PageRoute
          path="/terms-of-use-commercial"
          exact
          component={TermsOfUseCommercial}
        />
        <PageRoute
          path="/commercial-offerings"
          exact
          component={CommercialOfferings}
        />
        <PageRoute path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/commercial-use" exact>
          <Redirect to="/terms-of-use-commercial" />
        </Route>
        <PageRoute path="/privacy-takedown" exact component={PrivacyTakedown} />
        <PageRoute path="/object-detection" exact component={ObjectDetection} />
        <PageRoute
          path="/lidar-segmentation"
          exact
          component={LidarSegmentation}
        />
        <PageRoute path="/gallery" exact component={Gallery} />
        <PageRoute path="/tracking" exact component={Tracking} />
        <PageRoute path="/publications" exact component={Publications} />
        <PageRoute path="/nuimages" exact component={NuImages} />
        <PageRoute path="/nuscenes" exact component={NuScenes} />
        <PageRoute path="/nuplan" exact component={NuPlan} />
        <PageRoute path="/prediction" exact component={Prediction} />
        <PageRoute path="/panoptic" exact component={Panoptic} />
        <PageRoute path="/planning" exact component={ComingSoon} />

        <PageRoute
          path="/change-password"
          exact
          component={ChangePassword}
          isProtected
        />

        <Redirect from="/overview" to="/nuscenes#overview" />
        <Redirect from="/data-collection" to="/nuscenes#data-collection" />
        <Redirect from="/data-annotation" to="/nuscenes#data-annotation" />
        <Redirect from="/data-format" to="/nuscenes#data-format" />
        <Redirect from="/tutorial" to="/nuscenes?tutorial=nuscenes" />
        <Redirect from="/map-tutorial" to="/nuscenes?tutorial=maps" />
        <Redirect from="/explore" to="/nuscenes#explore" />
        <Redirect from="/lidarseg" to="/nuscenes#panoptic" />
        <Redirect from="/images" to="/nuimages" />

        <PageRoute path="*" component={NotFound404} />
      </Switch>
    </Suspense>
    <MessageBox />
  </div>
);

export default RoutesNuScenes;
