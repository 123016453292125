// Common code shared by both the nuScenes and nuReality sites

// Only affects localhost development. Toggles between testing nuReality and nuScenes
const testNuReality = false;

export const isLocalhost = window.location.hostname === "localhost";

export const weAreOnNuPlanDomain =
  window.location.hostname === "www.nuplan.org" ||
  window.location.hostname === "nuplan.org" ||
  new URLSearchParams(window.location.search).get("domain") === "nuplan";

export const weAreOnNuRealityDomain =
  (testNuReality && isLocalhost) ||
  window.location.hostname === "www.nureality.org" ||
  window.location.hostname === "nureality.org" ||
  new URLSearchParams(window.location.search).get("domain") === "nureality";

export const contactEmail = weAreOnNuRealityDomain
  ? "nuReality@motional.com"
  : "nuScenes@motional.com";

export const curDomainName = weAreOnNuRealityDomain
  ? "www.nuReality.org"
  : "www.nuScenes.org";

// ----------------- Shared UI used by Landing pages ------------------- //

// there are two absolute positioned button before and after slider for hooking arrows,
// so we should hide these button explicitly to avoid horizontal scrolling.
// see https://github.com/akiran/react-slick/issues/1517#issuecomment-511097110
export function NoArrow() {
  return <div style={{ display: "none" }} />;
}

export function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={className}
      onClick={onClick}
      style={style}
      role="button"
      tabIndex={0}
    >
      <svg
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          marginTop: "-30px",
          left: "-75px",
        }}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-100.000000, -1184.000000)"
            fill={className.includes("slick-disabled") ? "#ECECEC" : "#7c7c7c"}
          >
            <g transform="translate(100.000000, 910.000000)">
              <polygon
                id="Disclosure-Indicator-Copy"
                transform="translate(12.500000, 294.000000) scale(-1, 1) translate(-12.500000, -294.000000) "
                points="0 278.615385 4.6875 274 25 294 4.6875 314 0 309.384615 15.625 294"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={className}
      onClick={onClick}
      style={style}
      role="button"
      tabIndex={0}
    >
      <svg
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          marginTop: "-30px",
          left: "30px",
        }}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-1315.000000, -1184.000000)"
            fill={className.includes("slick-disabled") ? "#ECECEC" : "#7c7c7c"}
          >
            <g transform="translate(100.000000, 910.000000)">
              <polygon points="1215 278.615385 1219.6875 274 1240 294 1219.6875 314 1215 309.384615 1230.625 294" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
