export class Status {
  loading?: boolean = false;
  failed? = "";
  success?: boolean = false;
  progressing?: boolean = false;

  static createLoading() {
    const status = new Status();
    status.loading = true;
    return status;
  }

  static createSuccess() {
    const status = new Status();
    status.success = true;
    return status;
  }

  static createFailed(message = "") {
    const status = new Status();
    status.failed = message;
    return status;
  }

  static createProgressing() {
    const status = new Status();
    status.progressing = true;
    return status;
  }

  static createProcessingAndLoading() {
    const status = Status.createProgressing();
    status.loading = true;
    return status;
  }
}
