import ReactDOM from "react-dom";
import "isomorphic-fetch";
import App from "./components/App";
import * as Sentry from "@sentry/react";
import configureStore from "./store/configure-store";

Sentry.init({
  dsn: "https://e7bf3ea227d74bcbbb8540e70537f54a@o458014.ingest.sentry.io/5987501",
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const store = configureStore({});

ReactDOM.render(
  <App id="app-content" store={store} />,
  document.getElementById("root") as HTMLElement
);
