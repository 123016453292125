import _ from "lodash";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { checkLogin, noPermission } from "../Login/actions";
import styles from "./layout.module.scss";
import { Component } from "react";

const theme = createTheme({
  palette: {
    primary: blueGrey,
  },
});

class BaseLayout extends Component<any, any> {
  render() {
    const { auth, isProtected, noPermission } = this.props;
    if (isProtected && _.isBoolean(auth) && !auth) {
      noPermission();
      return <Redirect to={{ pathname: "/login" }} />;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className={styles.layout}>{this.props.children}</div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.login.auth,
  ...ownProps,
});

const mapDispatchToProps = {
  checkLogin,
  noPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
