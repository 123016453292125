import { Provider } from "react-redux";
import ReactGA from "react-ga"; // React Google Analytics
import history from "../utils/history";
import { loadConfig } from "../utils/config";

// This single codebase hosts two separate websites:
// www.nuscenes.org [src/components]
// www.nureality.org [src/componentsNuReality]
import RoutesNuScenes from "./Routes";
import RoutesNuReality from "../componentsNuReality/Routes";
import { weAreOnNuRealityDomain } from "../shared";

import "../styles/normalize.scss";
import "../styles/base.scss";
import "../styles/common.scss";
import "../styles/overwrite.scss";
import { Router } from "react-router";

const configs = loadConfig();
ReactGA.initialize(configs.googleTrackingID);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = ({ store }: any) => (
  <Provider store={store}>
    <Router history={history}>
      {weAreOnNuRealityDomain ? <RoutesNuReality /> : <RoutesNuScenes />}
    </Router>
  </Provider>
);

export default App;
