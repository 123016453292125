import React from "react";
import styles from "./footer.module.scss";
import { contactEmail, weAreOnNuRealityDomain } from "../../shared";

class Footer extends React.Component<any> {
  static readonly copyrightText = weAreOnNuRealityDomain
    ? "Copyright © 2021 nuReality"
    : "Copyright © 2020 nuScenes";

  render() {
    return (
      <section className={styles.wrapper}>
        <div className={styles.text}>{Footer.copyrightText}</div>
        <div className={styles.text}>All Rights Reserved</div>
        <div className={styles.text}>
          Contact Us :&nbsp;
          <a href={`mailto:${contactEmail}`} className={styles.email}>
            {contactEmail}
          </a>
        </div>
      </section>
    );
  }
}

export default Footer;
