import { Status } from "../../models/Status";
import * as T from "./types";

const initialState: T.IState = {
  status: new Status(),
  data: [],
};

export default (state: T.IState = initialState, action) => {
  switch (action.type) {
    case T.LEADER_BOARD_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case T.LEADER_BOARD_DATA:
      return {
        ...state,
        data: [...action.payload],
      };

    default:
      return {
        ...state,
      };
  }
};
