import * as T from "./types";
import { createAction } from "../../models/Action";

export const reset = () => {
  return (dispatch) => {
    dispatch(
      createAction(T.MESSAGE_BOX, {
        type: "",
        message: "",
      })
    );
  };
};

export const showMessageBox = (message = "", type = "error") => {
  return (dispatch) => {
    dispatch(
      createAction(T.MESSAGE_BOX, {
        message,
        type,
      })
    );
  };
};
