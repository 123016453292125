import { Status } from "../../models/Status";
import { CHANGE_PASSWORD_STATUS, IState } from "./types";

const initialState: IState = {
  status: new Status(),
};

export const changePasswordReducer = (
  state: IState = initialState,
  action
): IState => {
  switch (action.type) {
    case CHANGE_PASSWORD_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default changePasswordReducer;
