/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import history from "../../utils/history";
import { KeyboardArrowDown } from "@material-ui/icons";
import { menusNuScenes, IMenu } from "./MenusNuScenes";
import { menusNuReality } from "./MenusNuReality";
import styles from "./header.module.scss";
import { CrossLink, nuLogoImgSrc } from "./Header";
import { weAreOnNuPlanDomain, weAreOnNuRealityDomain } from "../../shared";

class PublicHeaderMoblie extends React.Component<
  any,
  {
    path: string;
    mobileMenusIsOpen: boolean;
    activeMenu: IMenu | null;
    scrollingUnderCarousel: boolean;
  }
> {
  removeListener: () => void;

  constructor(props) {
    super(props);
    this.state = {
      path: window.location.pathname,
      mobileMenusIsOpen: false,
      activeMenu: null,
      scrollingUnderCarousel: false,
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.removeListener = () => {};
  }

  componentDidMount() {
    this.removeListener = history.listen((location) => {
      this.setState({
        path: location.pathname,
        mobileMenusIsOpen: false,
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    });

    window.addEventListener("scroll", () => {
      this.setState({
        scrollingUnderCarousel: window.pageYOffset > 450,
      });
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }

  toggleMobileMenu = (open?: boolean) => (e) => {
    e.stopPropagation();
    this.setState({
      mobileMenusIsOpen:
        open === undefined ? !this.state.mobileMenusIsOpen : open,
    });
  };

  setActiveSubMenus = (menu) => () => {
    this.setState({
      activeMenu: menu === this.state.activeMenu ? null : menu,
    });
  };

  render() {
    const { mobileMenusIsOpen, path, activeMenu, scrollingUnderCarousel } =
      this.state;
    const routes = path.split("/").slice(0, 2);
    const page = routes.join("/");
    const menus = weAreOnNuRealityDomain ? menusNuReality : menusNuScenes;

    return (
      <div>
        <div
          className={
            styles.mobileMenus +
            " " +
            (mobileMenusIsOpen ? styles.isOpen : "") +
            " " +
            (scrollingUnderCarousel ? styles.dark : "")
          }
        >
          <div
            style={{ marginTop: weAreOnNuPlanDomain ? 0 : "3px" }}
            className="flex vertical-center"
          >
            <Link style={{ lineHeight: 1 }} to="/">
              <img
                src={nuLogoImgSrc}
                alt="logo"
                className={styles.logoMobile}
              />
            </Link>
            <div className={styles.icon} onClick={this.toggleMobileMenu()}>
              {mobileMenusIsOpen ? (
                <svg className={styles.closeIcon}>
                  <line
                    x1="1"
                    y1="15"
                    x2="15"
                    y2="1"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="15"
                    y2="15"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 14 14"
                  className="cursor-pointer"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g fill="#fff">
                      <path d="M0,1 L14,1 L14,2.75 L0,2.75 L0,1 Z M0,5.66666667 L14,5.66666667 L14,7.41666667 L0,7.41666667 L0,5.66666667 Z M0,10.3333333 L14,10.3333333 L14,12.0833333 L0,12.0833333 L0,10.3333333 Z"></path>
                    </g>
                  </g>
                </svg>
              )}
            </div>
          </div>
          {mobileMenusIsOpen && (
            <ul className={styles.menus}>
              {menus.map((menu) => {
                const active =
                  page === menu.href ||
                  (menu.subMenus || []).find((s) => page === s.href);
                return (
                  <li key={menu.label} className={styles.menu}>
                    {menu.subMenus && (
                      <KeyboardArrowDown
                        onClick={this.setActiveSubMenus(menu)}
                        style={{ fontSize: 20 }}
                        className={
                          styles.downIcon +
                          " " +
                          (activeMenu === menu ? styles.active : "")
                        }
                      />
                    )}
                    <span
                      onClick={this.setActiveSubMenus(menu)}
                      className={
                        styles.menuLink +
                        " " +
                        (active ? styles.active : "") +
                        " " +
                        (menu.subMenus ? "" : styles.shiftRight)
                      }
                    >
                      {menu.subMenus ? (
                        menu.label
                      ) : (
                        <CrossLink
                          exact
                          external={
                            menu.href !== "/nuplan" && weAreOnNuPlanDomain
                              ? "https://www.nuscenes.org"
                              : undefined
                          }
                          to={menu.href}
                          className={
                            menu.label === "Download"
                              ? styles.signUp
                              : styles.mobileLink
                          }
                        >
                          {menu.label}
                        </CrossLink>
                      )}
                    </span>
                    {menu.subMenus && activeMenu === menu && (
                      <ul className={styles.subMenus}>
                        {menu.subMenus.map((s) => {
                          return (
                            <li key={s.label} className={styles.subMenu}>
                              {s.href.indexOf("http") === 0 ? (
                                <a
                                  href={s.href}
                                  target="_blank"
                                  className={styles.subMenu}
                                  rel="noreferrer"
                                >
                                  {s.label}
                                </a>
                              ) : (
                                <CrossLink
                                  exact
                                  external={
                                    weAreOnNuPlanDomain
                                      ? "https://www.nuscenes.org"
                                      : undefined
                                  }
                                  to={s.href}
                                  className={styles.subMenu}
                                >
                                  {s.label}
                                </CrossLink>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default PublicHeaderMoblie;
