export interface IMenu {
  label: string;
  href: string;
  subMenus?: IMenu[];
  submenuHorizOffset?: string;
}

const menusNuScenes: IMenu[] = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "nuPlan",
    href: "/nuplan",
  },
  {
    label: "nuScenes",
    href: "/nuscenes",
  },
  {
    label: "nuImages",
    href: "/nuimages",
  },
  {
    label: "nuReality",
    href: "https://www.nureality.org",
  },
  {
    label: "Tasks",
    href: "/object-detection",
    submenuHorizOffset: "-50px",
    subMenus: [
      {
        label: "Detection",
        href: "/object-detection",
      },
      {
        label: "Tracking",
        href: "/tracking",
      },
      {
        label: "Prediction",
        href: "/prediction",
      },
      {
        label: "Lidar Segmentation",
        href: "/lidar-segmentation",
      },
      {
        label: "Panoptic",
        href: "/panoptic",
      },
      {
        label: "Planning",
        href: "/planning",
      },
    ],
  },
  {
    label: "About",
    href: "/about",
    submenuHorizOffset: "-190px",
    subMenus: [
      {
        label: "Forum",
        href: "https://forum.nuscenes.org",
      },
      {
        label: "Publications",
        href: "/publications",
      },
      {
        label: "Gallery",
        href: "/gallery",
      },
      {
        label: "Team",
        href: "/team",
      },
      {
        label: "Terms of use - Non-Commercial",
        href: "/terms-of-use",
      },
      {
        label: "Terms of use - Commercial",
        href: "/terms-of-use-commercial",
      },
      {
        label: "Commercial Offerings",
        href: "/commercial-offerings",
      },
      {
        label: "Privacy Policy",
        href: "/privacy",
      },
    ],
  },
  {
    label: "  ",
    href: "",
  },
];

export { menusNuScenes };
