import { connect } from "react-redux";
import { IconButton, Slide, Snackbar } from "@material-ui/core";
import { Close, InfoOutlined } from "@material-ui/icons";
import { reset } from "./actions";
import { Component } from "react";

function TransitionRight(props: any) {
  return <Slide {...props} direction="left" />;
}

class MessageBox extends Component<any> {
  close = () => {
    this.props.reset();
  };

  render() {
    const { type, message } = this.props;
    return (
      <Snackbar
        className="flex"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={TransitionRight}
        open={!!message}
        autoHideDuration={6000}
        onClose={this.close}
        message={
          <div className="vertical-center">
            <InfoOutlined
              style={{ color: type === "error" ? "red" : "green" }}
            />
            &nbsp;&nbsp;
            <div>{message}</div>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.close}
          >
            <Close />
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  type: state.messageBox.type,
  message: state.messageBox.message,
});

const mapDispatchToProps = {
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
