import React from "react";
import MediaQuery from "react-responsive";

const breakpoints = {
  desktop: "(min-width: 992px)",
  tablet: "(min-width: 768px) and (max-width: 1200px)",
  phone: "(max-width: 991px)",
  widerDesktop: "(min-width: 1200px)",
  mobile: "(max-width: 1199px)",
  narrowScreen: "(max-width: 767px)",
  wideScreen: "(min-width: 768px)",
};

export default class Responsive extends React.Component<any> {
  render() {
    const { name = "desktop", children } = this.props;
    const breakpoint = breakpoints[name];
    return (
      <MediaQuery {...this.props} query={breakpoint}>
        {children}
      </MediaQuery>
    );
  }
}
